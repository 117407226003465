@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
    overflow-y: scroll; /* Ensures content is still scrollable */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }
  
  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }